<!--
 * @Description: 回访管理
 * @Author: ChenXueLin
 * @Date: 2021-09-15 15:35:49
 * @LastEditTime: 2022-07-15 15:52:05
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      :element-loading-background="loadingBackground"
      v-loading="loading"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="questionId">
              <el-input
                v-model="searchForm.questionId"
                placeholder="问题ID"
                title="问题ID"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="questionContext">
              <el-input
                v-model="searchForm.questionContext"
                placeholder="问题内容"
                title="问题内容"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="returnTypeCode">
              <e6-vr-select
                v-model="searchForm.returnTypeCode"
                placeholder="所属回访类型"
                title="所属回访类型"
                :data="visiteTypeList"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="isenable">
              <e6-vr-select
                v-model="searchForm.isenable"
                :data="statusList"
                placeholder="状态"
                title="状态"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="mustNote">
              <e6-vr-select
                v-model="searchForm.mustNote"
                :data="mustNoteList"
                placeholder="原因是否必填"
                title="原因是否必填"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="createdBy">
              <el-input
                v-model="searchForm.createdBy"
                placeholder="创建人"
                title="创建人"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--2" prop="createTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="创建时间"
                ref="effectiveDatePicker"
                v-model="searchForm.createTime"
                title="创建时间"
                :picker-options="pickerOptions('searchForm.createTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="创建时间（始）"
                end-placeholder="创建时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i class="e6-icon-add_line" title="新增" @click="addQues"></i>
          <i
            class="e6-icon-check_line1"
            title="启用"
            @click="changeStatus('enable')"
          ></i>
          <i
            class="e6-icon-prohibit_line"
            title="禁用"
            @click="changeStatus('disable')"
          ></i>
          <i class="e6-icon-clear_line" title="删除" @click="handleDelete"></i>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          ref="tableList"
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="40"></el-table-column>
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="120"
          >
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList(scope)"
                @command="handleOperate($event, scope)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
    <!-- 编辑弹框 -->
    <el-dialog
      v-dialogDrag
      title="添加问题"
      :visible.sync="editDialog"
      width="550px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      v-loading="editDialogLoading"
      :element-loading-background="loadingBackground"
      custom-class="edit-customer-dialog"
    >
      <el-form
        ref="addQuestionForm"
        :model="addQuestionForm"
        :rules="addQuestionRules"
        lbel-width="120px"
      >
        <el-form-item label="问题内容" prop="questionContext">
          <el-input
            v-model.trim="addQuestionForm.questionContext"
            placeholder="问题内容"
            style="width:300px;"
          ></el-input>
        </el-form-item>
        <el-form-item label="答案选项" prop="answerList" class="block-form">
          <div class="options-content"></div>
          <div class="options-content">
            <div
              class="options"
              v-for="(item, index) in addQuestionForm.answerList"
              :key="index"
            >
              <span>选项{{ index + 1 }}</span>
              <el-input v-model="item.answerContext"></el-input>
              <i
                class="e6-icon-add_fill"
                @click="pushAnswer"
                v-if="index + 1 == addQuestionForm.answerList.length"
              ></i>
              <i class="e6-icon-minus_fill" @click="subAnswer(index)"></i>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="是否必填备注" prop="mustNote">
          <e6-vr-select
            v-model="addQuestionForm.mustNote"
            :data="mustNoteList"
            placeholder="是否必填备注"
            title="是否必填备注"
            clearable
          ></e6-vr-select>
        </el-form-item>
        <el-form-item label="所属回访类型" prop="returnTypeCode">
          <e6-vr-select
            v-model="addQuestionForm.returnTypeCode"
            placeholder="所属回访类型"
            title="所属回访类型"
            :data="visiteTypeList"
            clearable
            :props="{
              id: 'codeValue',
              label: 'codeName'
            }"
          ></e6-vr-select>
        </el-form-item>
      </el-form>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleSubmit">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import {
  getQuestionList,
  findDownList,
  deleteQuestion,
  batchQuestion,
  getQuestionDetail,
  editQuestion,
  updateOrderNo,
  addQuestion
} from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "visitQues",
  components: {},
  mixins: [listPage, listPageReszie, base],
  data() {
    return {
      loading: false,
      visiteTypeList: [], //回访类型下拉框
      statusList: [
        { id: 1, label: "正常" },
        { id: 0, label: "禁用" }
      ], //状态下拉框
      mustNoteList: [
        {
          id: 1,
          label: "必填"
        },
        {
          id: 0,
          label: "非必填"
        }
      ], //是否必填
      total: 0,
      searchForm: {
        questionId: "", //问题ID
        questionContext: "", //问题内容
        returnTypeCode: "", //回访类型
        isenable: "", //状态
        mustNote: "", //原因是否必填
        createdBy: "", //创建人
        createTime: [],
        endTimeTimestamp: "", //结束时间
        beginTimeTimestamp: "", //开始时间
        pageIndex: 1,
        pageSize: 20
      },
      columnData: [
        {
          fieldName: "questionId",
          display: true,
          fieldLabel: "问题ID",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "questionContext",
          display: true,
          fieldLabel: "问题内容",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "answerString",
          display: true,
          fieldLabel: "问题答案",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "mustNoteValue",
          display: true,
          fieldLabel: "是否必填备注",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "returnType",
          display: true,
          fieldLabel: "所属回访类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "enableStatus",
          display: true,
          fieldLabel: "状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdByName",
          display: true,
          fieldLabel: "创建人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdTimeString",
          display: true,
          fieldLabel: "创建时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [],
      selColumnId: [], //勾选的数据
      questionInfo: {}, //问题详情
      /***新增修改问题****/
      type: "", //add新增edit编辑
      editDialog: false,
      editDialogLoading: false,
      addQuestionForm: {
        answerList: [{ answerContext: "" }], //答案选项
        mustNote: 1, //是否必填备注
        questionContext: "", //回访问题内容
        returnTypeCode: "" //回访类型
      },
      addQuestionRules: {
        questionContext: [
          {
            required: true,
            message: "请输入问题内容",
            trigger: ["blur", "change"]
          }
        ],
        answerList: [
          {
            required: true,
            message: "请输入问题答案",
            trigger: ["blur", "change"]
          }
        ],
        mustNote: [
          {
            required: true,
            message: "请选择是否必填",
            trigger: ["blur", "change"]
          }
        ],
        returnTypeCode: [
          {
            required: true,
            message: "请选择回访类型",
            trigger: ["blur", "change"]
          }
        ]
      },
      refTable: "tableList",
      queryListAPI: getQuestionList
    };
  },
  computed: {},
  watch: {
    // 创建开始时间
    "searchForm.createTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.createTime = this.searchForm.createTime.reverse();
        }
        this.searchForm.beginTimeTimestamp = val[0] || "";
        this.searchForm.endTimeTimestamp = val[1] || "";
      }
    }
  },
  created() {
    this.getVisiteType();
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM();
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    //查询回访类型下拉框
    async getVisiteType() {
      try {
        this.loading = true;
        let res = await findDownList(["RevisitType"]);
        this.visiteTypeList = this.getFreezeResponse(res, {
          path: "data.RevisitType"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 表格勾选时间
    handleSelectionChange(columns) {
      this.selColumnId = columns.map(item => {
        return Object.assign(
          {},
          {
            id: item.questionId,
            versionNumber: item.versionNumber
          }
        );
      });
    },
    //批量删除
    handleDelete() {
      if (!this.selColumnId.length) {
        this.$message.warning("请选择数据");
        return;
      }
      this.$confirm("是否确认删除？", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.deleteQuestion();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    //删除客户请求
    async deleteQuestion() {
      try {
        let res = await deleteQuestion({ ids: this.selColumnId });
        if (res.code == "OK") {
          this.$message.success("删除成功");
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //处理启用禁用
    changeStatus(status) {
      if (this.selColumnId.length) {
        console.log(status);
        let tipTitle =
          status == "enable"
            ? "是否确认启用勾选的数据？"
            : "是否确认禁用勾选的数据？";
        let title = status == "enable" ? "启用问题" : "禁用问题";
        this.$confirm(tipTitle, title, {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            this.batchQuestion(status);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消"
            });
          });
      } else {
        this.$message.warning("未勾选数据");
      }
    },
    //批量启用请求
    async batchQuestion(type) {
      try {
        this.loading = true;
        let res = await batchQuestion({ ids: this.selColumnId, type });
        if (res.code == "OK") {
          this.$message.success("操作成功");
          this.selColumnId = [];
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //****新增、编辑****** */
    //获取问题详情
    async getDetail(id) {
      try {
        this.editDialogLoading = true;
        let res = await getQuestionDetail({ id });
        this.questionInfo = this.getFreezeResponse(res, {
          path: "data"
        });
        this.addQuestionForm = _.cloneDeep(this.questionInfo);
      } catch (error) {
        printError(error);
      } finally {
        this.editDialogLoading = false;
      }
    },
    //添加问题答案
    pushAnswer() {
      this.addQuestionForm.answerList.push({});
    },
    //点击减号删除答案
    subAnswer(index) {
      if (this.addQuestionForm.answerList.length > 1) {
        this.addQuestionForm.answerList.splice(index, 1);
      } else {
        this.$message.warning("最少需要一个答案,不能继续删除");
      }
    },
    //点击确定新增回访问题
    handleSubmit() {
      this.$refs.addQuestionForm.validate(valid => {
        if (valid) {
          if (!this.addQuestionForm.answerList.length) {
            this.$message.warning("答案不能为空");
            return;
          }

          if (
            this.addQuestionForm.answerList.some(item => {
              return !item.answerContext;
            })
          ) {
            this.$message.warning("答案选项不能为空");
            return;
          }
          if (this.type == "add") {
            this.addReq();
          } else {
            this.editReq();
          }
        }
      });
    },

    //添加回访问题请求
    async addReq() {
      try {
        this.editDialogLoading = true;
        let res = await addQuestion(this.addQuestionForm);
        if (res.code == "OK") {
          this.$message.success("新增成功");
          this.handleClose();
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.editDialogLoading = false;
      }
    },
    //编辑问题请求
    async editReq() {
      try {
        this.editDialogLoading = true;
        let res = await editQuestion(this.addQuestionForm);
        if (res.code == "OK") {
          this.$message.success("编辑成功");
          this.handleClose();
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.editDialogLoading = false;
      }
    },

    // 获取页面操作权限
    getOperateList(scope) {
      // 页面按钮权限
      let operates = [
        {
          label: "上移",
          id: 2
        },
        {
          label: "下移",
          id: 3
        },
        {
          label: "编辑",
          id: 1
        }
      ];
      if (scope.$index == 0) {
        // 第一个
        operates = [
          {
            label: "下移",
            id: 3
          },

          {
            label: "编辑",
            id: 1
          }
        ];
      }
      if (scope.$index == this.tableData.length - 1) {
        // 第一个
        operates = [
          {
            label: "上移",
            id: 2
          },
          {
            label: "编辑",
            id: 1
          }
        ];
      }

      return operates;
    },
    // 操作--更多
    handleOperate(val, scope) {
      // row
      if (val.id == 1) {
        this.type = "edit";
        this.editDialog = true;
        this.getDetail(scope.row.questionId);
      }
      let index = scope.$index;
      if (val.id == 2) {
        //上移动
        let data = {
          upId: scope.row.questionId, //上移id
          upVersionNumber: scope.row.versionNumber,
          downId: this.tableData[index - 1].questionId,
          downVersionNumber: this.tableData[index - 1].versionNumber
        };
        this.updateOrderNoReq(data);
      }
      if (val.id == 3) {
        //下移动
        let data = {
          upId: this.tableData[index + 1].questionId,
          upVersionNumber: this.tableData[index + 1].versionNumber,
          downId: scope.row.questionId,
          downVersionNumber: scope.row.versionNumber
        };
        this.updateOrderNoReq(data);
      }
    },
    //问题排序
    async updateOrderNoReq(data) {
      try {
        this.editDialogLoading = true;
        let res = await updateOrderNo(data);
        if (res.code == "OK") {
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.editDialogLoading = false;
      }
    },
    //添加问题
    addQues() {
      this.editDialog = true;
      this.type = "add";
    },
    //关闭弹框置空数据
    handleClose() {
      this.addQuestionForm = {
        answerList: [{ answerContext: "" }], //答案选项
        mustNote: 1, //是否必填备注
        questionContext: "", //回访问题内容
        returnTypeCode: "" //回访类型
      };
      this.$refs.addQuestionForm.resetFields();
      this.editDialog = false;
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .edit-customer-dialog {
  .el-form {
    padding: 20px 20px 0;
    .block-form .el-form-item__label {
      float: none;
    }
    .options-content {
      display: block;
      width: 100%;
      margin-left: 15px;
      .options {
        font-size: 12px;
        color: #606266;
        display: flex;
        align-items: center;
        margin-top: 10px;
        i {
          margin-left: 10px;
          height: 28px;
          margin-right: 10px;
          color: #46bfea;
          cursor: pointer;
        }
        [class^="e6-icon"] {
          font-size: 20px;
        }
        .el-input {
          width: 300px;
          margin-left: 25px;
        }
      }
    }
  }
}
</style>
